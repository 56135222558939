import { useState, useEffect, useRef } from "react";
import logo from './logo.svg';
import './App.css';
import Navlogo from "./assets/navLogo.png";
import Advantage from "./assets/advantage.png";
// import  from "./assets/.png";
import Bravo from "./assets/bravo.png";
import Brand from "./assets/brand.png";
import BrandMobile from "./assets/projectMobile.png"
import MainLogo from "./assets/mainLogo.png";

import Project1 from "./assets/projects/1.gif"
import ProjectQr1 from "./assets/projects/qr1.png"
import Project2 from "./assets/projects/2.gif"
import ProjectQr2 from "./assets/projects/qr2.png"
import Project3 from "./assets/projects/3.gif"
import ProjectQr3 from "./assets/projects/qr3.png"
import Project4 from "./assets/projects/4.gif"
import ProjectQr4 from "./assets/projects/qr4.png"

import Social1 from "./assets/social/1.png"
import Social2 from "./assets/social/2.png"
import Social3 from "./assets/social/3.png"
import Social4 from "./assets/social/4.png"
import Social5 from "./assets/social/5.png"

import Advan1 from "./assets/advan/1.png";
import Advan2 from "./assets/advan/2.png";
import Advan3 from "./assets/advan/3.png";
import { useHistory } from "react-router-dom";
import ArButton from "./assets/tryInAr.png";
import bottomIcon from "./assets/bottomIcon.png"


function MainPage() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [message, setmessage] = useState("");
  const [showIframeOverlay, setShowIframeOverlay] = useState(true);
  let history = useHistory();
  const projects = [
    {
      name: "Bike Configurator",
      img: Project1,
      qrImg: ProjectQr1,
      qrUrl: "https://catjzzbx.8thwall.app/dj-bike-configurator/"
    },
    {
      name: "Learning Module",
      img: Project2,
      qrImg: ProjectQr2,
      qrUrl: "https://catjzzbx.8thwall.app/globalgoals/"
    },
    {
      name: "Product Launch",
      img: Project3,
      qrImg: ProjectQr3,
      qrUrl: "https://catjzzbx.8thwall.app/bd-ps-c/"
    },
    {
      name: "Furniture Showcase",
      img: Project4,
      qrImg: ProjectQr4,
      qrUrl: "https://apps.8thwall.com/senyuuif/asianpaints/"
    },
  ]

  const handleScrollDown = () => {
    let ourProjects = document.getElementById("ourProjects")
    ourProjects.scrollIntoView()
  }

  return (
    <div className="App"
      onClick={() => {
        if (!showIframeOverlay)
          setShowIframeOverlay(true);
      }}
    >

      {/* HAED  */}
      <div className='head'>
        {/* NAVBAR */}
        <div className='navbar wid-85'>
          <img src={Navlogo} className="nav-logo" />
          <a className='contact-us clr-white font-rale bold-5' href="#queryForm">Contact Us</a>
        </div>

        <div className='head-data-cont wid-85'>
          <div className='head-data'>
            <p className=' clr-white font-b head-main-heading font-rale'>The Future <br className="break-line" /> is here</p>
            <p className=' clr-white head-main-line font-rale'>Explore the world <br className="break-line" /> using <b>WebAR</b></p>
            <div className='bike-qr-container'>
              <img src={ProjectQr1} className="bike-qr" />
              <div className='bike-qr-text-container'>
                <p className=' clr-white font-rale bold-8 margin-0 f-s-3'>View in AR</p>
                <p className=' clr-white font-rale bold-3 margin-0 f-s-1-5'>Scan the QR code to <br /> view this model in AR.</p>
              </div>
            </div>
          </div>
          <div className='ar-cont'>
            <iframe src={"./bikeModal/index.html"} className="bike-iframe"
              onClick={() => { setShowIframeOverlay(true) }}
            ></iframe>
            {showIframeOverlay && <div className="iframe-overlay"
              onClick={() => { setShowIframeOverlay(false) }}
            >
              <p>{"<< Tap to Interact >>"}</p>
            </div>}

          </div>

        </div>
        <div onClick={handleScrollDown} className="scrollDown">
          Scroll down
          <img src={bottomIcon} alt="" />
        </div>
      </div>

      {/* PROJECTS */}
      <div className='project' id="ourProjects">
        <p className='project-head clr-blue'>Our Key Projects</p>
        <div className='project-cont'>
          {projects.map((data, index) => {
            return (
              <div className='indi-pro-cont'>
                <img src={data.img} className="indi-pro-img" />
                <div className='ini-pro-data'>
                  <p>{data.name}</p>
                  <img src={data.qrImg} className="pc-qr" />
                  <img src={ArButton} className="mobile-qr" onClick={() => {
                    window.location.href = data.qrUrl;
                  }} />
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* FORM */}
      <div className='form wid-85' id="queryForm">
        <div className='form-tagline-cont'>
          <p>Let’s join hands<br className="break-line" /> to build your next<br className="break-line" /> WebAR Solution!</p>
        </div>
        <form onSubmit={(e) => {
          e.preventDefault();
          var myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");

          var raw = JSON.stringify({
            "name": name,
            "message": message,
            "email": email
          });

          var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
          };

          fetch("https://googleads-mailer-dot-virtual-event-273009.appspot.com/message", requestOptions)
            .then(response => response.text())
            .then(async result => {
              console.log(result);


              const data = {
                // Timestamp: new Date(),
                name: name,
                email: email,
                Query: message
              };

              // Add one line to the sheet
              fetch("https://sheet.best/api/sheets/563403ee-0eba-4358-96c0-f0fde71831eb", {
                method: "POST",
                mode: "cors",
                headers: {
                  "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
              })
                .then((r) => r.json())
                .then((data) => {
                  // The response comes here
                  console.log("added to sheets" + data);
                })
                .catch((error) => {
                  // Errors are reported there
                  console.log("error adding to sheets" + error);
                });



              await setmessage("");
              await setname("");
              await setemail("");
              history.push("/formsubmit")

            })
            .catch(error => console.log('error', error));
        }}>
          <div className="form-data-cont">
            <p className="form-feilds-head font-rale">Name</p>
            <input type="" name="" required onChange={(e) => { setname(e.target.value) }} value={name} placeholder="" className="input-feild" />
            <p className="form-feilds-head font-rale">Email</p>
            <input type="email" name="" required onChange={(e) => { setemail(e.target.value) }} value={email} placeholder="" className="input-feild" />
            <p className="form-feilds-head font-rale">Message</p>
            <textarea type="" name="" required onChange={(e) => { setmessage(e.target.value) }} value={message} placeholder="" className="textarea-feild" />
            <button className="form-sub-butt" type="submit">Send Message</button>
            {/* <p className="respond-message">Our Representive will respond your next 24 hrs.</p> */}
          </div>
        </form>
      </div>

      {/* ADVANTAGES */}
      <div className='advantage'>
        <p className="clr-white">WebAR Viewer Advantages</p>
        <div className="advantage-img-cont wid-85">
          <div className="ad-block">
            <div className="ad-block-img-cont">

              <img src={Advan1} className="ad-block-img" />
            </div>
            <p className="ad-block-text">Easy Access</p>
          </div>
          <div className="ad-block" >
            <div className="ad-block-img-cont">

              <img src={Advan2} className="ad-block-img" />
            </div>
            <p className="ad-block-text">Elevate Your Brand</p>
          </div>
          <div className="ad-block">
            <div className="ad-block-img-cont">

              <img src={Advan3} className="ad-block-img" />
            </div>
            <p className="ad-block-text">Powerful Product <br className="break-line" />Representation</p>
          </div>
        </div>
      </div>

      {/* CLIENTS */}
      <div className='clients' id="ourClients">
        <p className="client-head clr-blue">Our Clients</p>
        <img src={Brand} className="brand-image-pc" />
        <img src={BrandMobile} className="brand-image-mobile" />

      </div>

      {/* LETS TALK */}
      <div className='lets-talk'>
        <p>Let’s build your next WebAR campaign✌</p>
        <a href="#queryForm"><p style={{ textAlign: "center", margin: 0 }}>Let’s Talk</p></a>
      </div>

      {/* FOOTER */}
      <div className='footer wid-85'>
        <div className="about-us-cont" id="aboutUs">
          <p className="about-head">About Us</p>
          <p className="about-desc">Digital Jalebi is a fusion of new media design and technology, and draws heavily from digital art, visual communication, architecture and programming.
          </p>
        </div>
        <div className="comp-social-cont">
          <div className="social-cont">
            <img src={MainLogo} />
            <p>Get in Touch!</p>
            <p>fariha@digitaljalebi.com</p>
            {/* <p>G 202, G Block, Sector 63, Noida, Uttar Pradesh 201301</p> */}
            <div className="social-link-cont">
              <div className="social-link" onClick={() => {
                window.location.href = "https://www.instagram.com/digitaljalebi/?hl=en";
              }}>
                <img src={Social1} className="social-link-img" />
              </div>
              <div className="social-link" onClick={() => {
                window.location.href = "https://www.facebook.com/digitaljalebi/";
              }}>
                <img src={Social2} className="social-link-img" />
              </div>
              <div className="social-link" onClick={() => {
                window.location.href = "https://in.linkedin.com/company/digital-jalebi";
              }}>
                <img src={Social3} className="social-link-img" />
              </div>
              <div className="social-link" onClick={() => {
                window.location.href = "https://hello-digitaljalebi.medium.com/";
              }}>
                <img src={Social4} className="social-link-img" />
              </div>
              <div className="social-link" onClick={() => {
                window.location.href = "https://www.youtube.com/channel/UCgDWh1QSfe8b2dE5Ujnnp6w";
              }}>
                <img src={Social5} className="social-link-img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <p className="copyright-line">© DJ Interactive Labs Pvt. Ltd. </p> */}
    </div>
  );
}

export default MainPage;
