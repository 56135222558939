import {useState, useEffect,useRef} from "react";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import MainPage from "./MainPage";
import FormSubmit from "./FormSubmit.js";
import TagManager from 'react-gtm-module';

function App() {
  return (
    <Router>
      <div>
        {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
        <Switch>
          <Route exact path="/">
            <MainPage />
          </Route>
          <Route exact path="/formsubmit">
            <FormSubmit />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

export default App;
